import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Footer from "web/footers/footer.js";
import Hero from "web/hero/BackgroundAsImage.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2048</p>

            
            <p>
            This Privacy Policy explains how Aditya Tours & Travlesa as Easy Cabs Booking ("we," "us," or "our") collects, uses, discloses, and protects your personal information when you use our services, website, and mobile applications (collectively, "Services").
            </p>
            <h3>Information We Collect</h3>
            <p>
              <b>Personal Information: </b>We may collect Name, phone number, email address, physical address, and other details provided during the registration or booking process.
              <br/>
              <b>Location Information:</b> Pickup and drop-off addresses provided during the booking.
            </p>
            <h3>How We Use Your Information</h3>
            <p>We may use your personal information for the following purposes:</p>
            <ul>
              <li>
              Booking Services: To process your booking requests, communicate with drivers, and provide our transportation services.
              </li>
              <li>
              Payment Processing: To process payments and issue receipts.
              </li>
              <li>
              Customer Support: To respond to your queries, requests, or complaints.
              </li>
              <li>
              Improvement of Services: To analyze usage trends, improve our Services, and personalize your experience.
              </li>
              <li>
              Marketing and Promotions: With your consent, we may send you promotional offers or updates about our services.
              </li>
              <li>
              Legal Compliance: To comply with legal obligations, regulations, or respond to lawful requests for information.
              </li>
            </ul>
            
            <h3>Sharing Your Information</h3>
            <p>We may share your personal information in the following circumstances:</p>
            <ul>
              <li>
              With Drivers: Your booking details (such as name, pickup location, destination) will be shared with the assigned driver.
              </li>
              <li>
              Third-Party Service Providers: We may share your information with payment processors, IT service providers, and other partners who assist us in delivering our services.
              </li>
              <li>
              Legal Compliance: We may disclose your information to law enforcement or regulatory authorities when required by law or in response to legal requests.
              </li>
              <li>
              Business Transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
              </li>
            </ul>
            <h3>Data Security</h3>
            <p>We are committed to protecting your personal information. We implement appropriate security measures (e.g., encryption, firewalls) to safeguard your data from unauthorized access, disclosure, or loss. However, no system is completely secure, and we cannot guarantee the absolute security of your data.</p>
            <h3> Data Retention</h3>
            <p>We retain your personal information only for as long as it is necessary to fulfill the purposes for which it was collected, including legal, accounting, or reporting requirements.</p>
            
            <h3> Cookies and Tracking Technologies</h3>
            <p>Our website and app may use cookies and other tracking technologies to collect information about 
              your browsing activities. You can adjust your browser settings to refuse cookies, 
              but this may affect your experience with our Services.</p>

            <h3> Children's Privacy</h3>
            <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you believe that a child under 18 has provided us with personal information, please contact us, and we will take appropriate steps to delete such information.</p>
            
            <h3> Changes to This Privacy Policy</h3>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website with the "Effective Date" indicated at the top. Your continued use of our services after the update constitutes your acceptance of the changes.</p>
            
            <h3> Changes to This Privacy Policy</h3>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website with the "Effective Date" indicated at the top. Your continued use of our services after the update constitutes your acceptance of the changes.</p>
            
            <h3> Contact Us</h3>
            <p>If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:</p>
            <p>Email: easycabsbooking01@gmail.com</p>
            <p>Phone: 09673505741</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
