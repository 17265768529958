import React from "react";
import GlobalStyles from 'styles/GlobalStyles';

//import ComponentRenderer from "ComponentRenderer.js";
//import MainLandingPage from "MainLandingPage.js";
import ThankYouPage from "ThankYouPage.js";
import AboutUs from "./pages/AboutUs";
import ContactUsForm from "web/forms/ContactUsForm.js";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Trips from "./pages/Trips";
import Package from "./components/pricing/PackagePlans";
import Home from "./web/index";

import FAQ from "web/faqs/faq.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUsForm />} />
          <Route path="/Package" element={<Package />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsOfService" element={<TermsOfService />} />
          <Route path="/Trips/:title/:id" element={<Trips />} />
          <Route path="/ThankYou" element={<ThankYouPage />} />
        </Routes>
      </Router>
    </>
  );
}

