import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro"; 
import GitHubButton from "react-github-btn";

import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import logo from "images/logo.svg";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */


  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <NavRow>
            <LogoLink href="/">
              <img src={logo} alt="" />
              Treact
            </LogoLink>
            
          </NavRow>
          <HeroRow>
            <Heading>Thank You for Choosing Vishwa Car Rental!</Heading>
            <div tw="mt-12 text-justify">
            We sincerely appreciate your trust in us to plan your travel experience. Whether you’ve just booked a trip or are still exploring your options, we are here to ensure that your journey is as seamless and enjoyable as possible.
            </div>
            <div tw="mt-12 text-justify">
              Your satisfaction is our top priority, and we are excited to be a part of your adventure. Our team is dedicated to providing personalized service, and we will keep you informed every step of the way as we finalize the details of your itinerary.
            </div>
            <div tw="mt-12 text-justify">
              If you have any questions, need to make adjustments, or require further assistance, please don’t hesitate to contact us. We’re always here to help and make sure you have the perfect trip.
            </div>
            <div tw="mt-12 text-justify">
              Stay tuned for updates and travel tips from our team to help you make the most of your journey. We look forward to serving you again in the future!
            </div>
            <Heading>Wishing you safe and happy travels!</Heading>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
