import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Footer from "web/footers/footer.js";
import Hero from "web/hero/BackgroundAsImage.js";
import AboutUsInfo from "components/features/AboutUsInfo.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import OurValues from "components/features/OurValues.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <AboutUsInfo
        subheading={<Subheading>About Easy Cabs Booking</Subheading>}
        heading="We Provide All Type of Car for One Way, Outstaion, Corporate."
        buttonRounded={false}
        primaryButtonText="See Portfolio.."
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      
      <OurValues
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description=""
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: " We are here for you, anytime, anywhere! Our dedicated 24/7 customer support ensures that no matter where you are or what time it is, help is just a call away. From last-minute changes to travel plans or assistance during your trip, we’ve got you covered round the clock."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Quality and Excellence",
            description: "We strive to provide the best travel services by partnering with top-tier hotels, local guides, and transportation providers. Every detail of your journey is meticulously planned to ensure you receive the highest quality experience."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Your happiness is our priority. We are dedicated to ensuring every trip is a delightful experience, from the moment you book to the moment you return. Our 24/7 customer support reflects our commitment to being there whenever you need us."
          },
        ]}
        linkText=""
      />
      
      <Footer />
    </AnimationRevealPage>
  );
};
