import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
const Column = tw.div`md:w-full`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
//const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      
      <Content>
        <Row>
        <LogoContainer>
        <LogoImg src={"/assets/img/logo.png"} />
        <LogoText>Aditya Tours & Travles</LogoText>
        </LogoContainer>
        <Row>
          <LinkList>
            <LinkListItem>
              <Link href="/PrivacyPolicy">Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/TermsOfService">Terms & Conditions</Link>
            </LinkListItem>
          </LinkList>
        </Row>
        <br/>
        <div className="container mt-5">
          <div className="row text-white justify-content-center mt-3 pb-3">
            <div className="col-12 col-sm-6 col-lg-3 mx-auto">
            <h5 className="text-capitalize fw-bold">Aditya Tours & Travles</h5>
            <hr className="bg-white d-inline-block mb-4" style={{width: "60", height: "2"}}/><br/>
            <p className="lh-lg">
              Aditya Tours & Travles, 201 1st floore Maske building Gavhane vasti bhosari pune 411039
            </p><br/>
            <p>Email:easycabsbooking01@gmail.com</p><br/>
            <p>Call:9673505741</p><br/>
            <p>Call:9673646518</p><br/>
            <p className="lh-lg">
              We are Aditya Tours & Travles are registerd company having experience of 10+ years. and served more then 1000+ happy Customers.
            </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 mx-auto">
            <h5 className="text-capitalize fw-bold">Regular Trip</h5>
            <hr className="bg-white d-inline-block mb-4" style={{width: "60", height: "2"}}/>
            <ul className="list-inline campany-list">
            <li><a href="/Trips/Pune-To-Kolhapur/1">Pune To Kolhapur</a></li>
            <li><a href="/Trips/Pune-To-Mumbai/2">Pune To Mumbai</a></li>
              <li><a href="/Trips/Pune-To-Sambhaji-Nagar/4">Pune to Sambhaji Nagar</a></li>
              <li><a href="/Trips/Pune-To-nashik/5">Pune to Nashik</a></li>
              <li><a href="/Trips/Pune-To-Mahableswar/6">Pune To Mahableswar</a></li>
              <li><a href="/Trips/Pune-To-Shirdi/12">Pune To Shirdi</a></li>
              <li><a href="/Trips/Pune-To-Panchgani/11">Pune To Panchgani</a></li>
              <li><a href="/Trips/Pune-To-Goa/7">Pune To Goa</a></li>
              <li><a href="/Trips/Pune-To-Ashtavinayak/14">Pune To Ashtavinayak</a></li>
              <li><a href="#">Pune To Grishneshwar</a></li>             
              <li><a href="/Trips/Pune-To-Bhimshankar/15">Pune To Bhimashanker</a></li>           
              <li><a href="#">Pune To Lonvala Khandala</a></li>       
              <li><a href="#">Pune To Mumbai Airport</a></li>
              
            </ul>
            
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 mx-auto">
            <h5 className="text-capitalize fw-bold">Regular Trip</h5>
            <hr className="bg-white d-inline-block mb-4" style={{width: "60",height: "2"}}/>
            <ul className="list-inline campany-list">
              <li><a href="/Trips/Pune-To-pune/3">Mumbai To Pune</a></li>
              <li><a href="/Trips/Pune-To-Alibag/12">Pune To Alibag</a></li>
              <li><a href="/Trips/Pune-To-Malvan/8">Pune To Kokan</a></li>
              <li><a href="/Trips/Pune-To-Malvan/8">Pune To Malvan</a></li>
              <li><a href="/Trips/Pune-To-Malvan/8">Pune To Sindhudurg</a></li>
              <li><a href="/Trips/Pune-To-Ganpatipule/9">Pune To Ganpatipule</a></li>
              <li><a href="/Trips/Pune-To-Mumbai/2">Pune To Mumbai Airport</a></li>
              <li><a href="/Trips/Pune-To-pune/3">Mumbai To Pune Airport</a></li>
              <li><a href="/Trips/Pune-To-Beed/13">Pune To Beed</a></li>
              <li><a href="/Trips/Pune-To-Lonavala/">Pune To Lonavala Khandal</a></li>
              <li><a href="#">Pune To Nanded</a></li>
              <li><a href="#">Pune To Parbhani</a></li>
              <li><a href="#">Pune To Sangli</a></li>
              <li><a href="#">Pune To Audha Nagnath</a></li>
            </ul>
            </div>
            
          </div>
        </div>

          
            
            
          
          
          
          <CopyrightText>
            &copy; <a href="#">Easy Cabs Bookings</a> | Created by <a href="http://www.ellipsesofttech.com" target="_blank">Ellipse Software Technology</a>
          </CopyrightText>
        </Row>
      </Content>
      
    </Container>
  );
};
