import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as UsersIcon } from "feather-icons/dist/icons/users.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/3`;
const CardColumn = tw(Column)` md:w-1/4 xl:w-1/4 mt-2 ml-2 pt-5 xl:mt-0`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`w-full mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-40 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`text-primary-500 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-5`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8`;

export default () => {
  const cards = [
    {
      imageSrc:
        "/assets/img/cars/car_swift.png",
      type: "Hachback",
      pricePerDay: "11",
      title: "",
      userText: "3+1",
      bagsText: "2 Bags",
      acText: "AC"
    },
    {
      imageSrc:
        "/assets/img/cars/sd_car2.png",
      type: "Sedan",
      pricePerDay: "12",
      title: "",
      userText: "4+1",
      bagsText: "2 Bags",
      acText: "AC"
    },
    {
      imageSrc:
        "/assets/img/cars/ertiga_new.png",
      type: "Ertiga",
      pricePerDay: "15",
      title: "",
      userText: "6+1",
      bagsText: "3 Bags",
      acText: "AC"
    },
    {
      imageSrc:
        "/assets/img/cars/innova_car1.png",
      type: "Innova",
      pricePerDay: "18",
      title: "",
      userText: "7+1",
      bagsText: "3 Bags",
      acText: "AC"
    },
    {
      imageSrc:
        "/assets/img/cars/innova_crysta_car.png",
      type: "Innova Crysta",
      pricePerDay: "20",
      title: "",
      userText: "7+1",
      bagsText: "2 Bags",
      acText: "AC"
    },
    {
      imageSrc:
        "/assets/img/cars/17tempo.png",
      type: "Tempo Traveler",
      pricePerDay: "28",
      title: "",
      userText: "17+1",
      bagsText: "",
      acText: "No"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingTitle>Our Cars</HeadingTitle>
        <ThreeColumn className="row">
          {cards.map((card, index) => (
            <CardColumn key={index} className="col-md-4 col-sm-4">
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardType>{card.type}</CardType>
                    <CardPrice>
                      <CardPriceAmount>{card.pricePerDay}</CardPriceAmount> /KM
                    </CardPrice>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    <CardMetaFeature>
                      <UsersIcon /> {card.userText}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <BriefcaseIcon /> {card.bagsText}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <LocationIcon /> {card.acText}
                    </CardMetaFeature>
                  </CardMeta>
                  <CardAction><a href="tel:+91 9673505741">	Call To Book</a></CardAction>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
