import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { SectionHeading } from "components/misc/Headings";

import Footer from "web/footers/footer.js";
import Header from "web/hero/BackgroundAsImage.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: September 21, 2024</p>
            
            <p>Please read these terms and conditions carefully before using Aditya Tours & Travles Service.</p>

            <h1>Introduction</h1>
            <p>
              These Terms and Conditions govern the use of our cab booking services. By booking a cab with our agency, you agree to be bound by these terms.
            </p>

            <h3>Booking Confirmation </h3>
            <ul>
              <li>
                All bookings are subject to availability.
              </li>
              <li>
              A booking is considered confirmed only upon receipt of a booking confirmation via email, SMS, or app notification.
              </li>
              <li>
              Customers must provide accurate information (pickup location, destination, time, etc.) at the time of booking.
              </li>
            </ul>
            
            <h3>Payment Terms</h3>
            <ul>
              <li>
              Payments can be made via cash, credit/debit card, or online payment platforms.
              </li>
              <li>
              Payment is due at the time of the ride completion unless a pre-agreed payment method is established.
              </li>
              <li>
              Cancellation fees may apply if the booking is canceled within a certain period, as outlined in Section "Cancellation and Refund Policy".
              </li>
            </ul>

            <h3>Fares and Charges</h3>
            <ul>
              <li>
              Fares are calculated based on distance, time, and traffic conditions.
              </li>
              <li>
              Extra charges may apply for tolls, parking, waiting times, or additional stops.
              </li>
              <li>
              Rates may vary for outstation trips, night rides, or peak hour bookings.
              </li>
            </ul>
            <h3>Cancellation and Refund Policy</h3>
            <ul>
              <li>
              Cancellations made more than 24 hours before the pickup time are free of charge.
              </li>
              <li>
              30% of trip charges if cancelled before 18 hours
              </li>
              <li>
              60% of trip charges if cancelled before 12 hours
              </li><li>
              100% of trip charges if cancelled before 6 hours
              </li>
            </ul>
            
            <h3>Customer Responsibilities</h3>
            <ul>
              <li>
              Customers must ensure that they and any passengers comply with all local traffic laws.
              </li>
              <li>
              Smoking, consumption of alcohol, or illegal substances is strictly prohibited in the vehicle.
              </li>
              <li>
              Any damage caused to the vehicle by the customer or passengers will be charged to the customer.
              </li>
            </ul>
            
            <h3>Driver Conduct</h3>
            <ul>
              <li>
              Drivers are required to adhere to the highest standards of professionalism, safety, and cleanliness.
              </li>
              <li>
              In the event of inappropriate behavior by the driver, please report the incident to the US within 24 hours.
              </li>
            </ul>
            <h3>Easy Cabs Booking Liability</h3>
            <ul>
              <li>
              The agency will not be liable for any delays or cancellations due to circumstances beyond our control (weather conditions, traffic, vehicle breakdowns, etc.).
              </li>
              <li>
              We do not guarantee the availability of specific vehicle models.
              </li>
              <li>
              In case of any accidents or injuries during the ride, We holds valid insurance for third-party liability, but customers are advised to carry their personal insurance as well.
              </li>
            </ul>
            
            <h3>Luggage and Belongings</h3>
            <ul>
              <li>
              The agency is not responsible for the loss or damage of any luggage or personal belongings.
              </li>
              <li>
              Customers are responsible for ensuring that their luggage is securely loaded in the vehicle.
              </li>
            </ul>
            <h3>Changes to the Terms</h3>
            <ul>
              <li>
              The agency reserves the right to modify these terms at any time. Updated terms will be posted on our website 
              </li>
              <li>
              Continued use of our services after any changes constitutes acceptance of the new terms.
              </li>
            </ul>
            <h3>Governing Law</h3>
            <ul>
              <li>
              These Terms and Conditions are governed by the laws of Pune. Any disputes arising will be handled by the courts in this jurisdiction.
              </li>
            </ul>
            <h1>Contact Information</h1>
            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>

            <ul>
              <li>By email: easycabsbooking01@gmail.com</li>
              <li>By phone number: 09673505741</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
